import { Interface } from '@ethersproject/abi'
import { ChainId } from 'spaceswap-sdk-blast'
import V1_EXCHANGE_ABI from './v1_exchange.json'
import V1_FACTORY_ABI from './v1_factory.json'

const V1_FACTORY_ADDRESSES: { [chainId in ChainId]: string } = {
  [ChainId.BLAST]: '0xCAb98fEd113d403EbD9E294D80Bf92E3f19ddD57',
  [ChainId.BLASTSEPOLIA]: '0x7d9fe1433358432AdaF9CAe2Fd6f7C23dBA57EfC',

}

const V1_FACTORY_INTERFACE = new Interface(V1_FACTORY_ABI)
const V1_EXCHANGE_INTERFACE = new Interface(V1_EXCHANGE_ABI)

export { V1_FACTORY_ADDRESSES, V1_FACTORY_INTERFACE, V1_FACTORY_ABI, V1_EXCHANGE_INTERFACE, V1_EXCHANGE_ABI }
