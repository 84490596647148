import { ChainId } from 'spaceswap-sdk-blast'
import MULTICALL_ABI from './abi.json'

const MULTICALL_NETWORKS: { [chainId in ChainId]: string } = {
  [ChainId.BLAST]: '0x1395504A6dDf3ABee75a155B8aA4b8c9983e2deF',
  [ChainId.BLASTSEPOLIA]: '0x1395504A6dDf3ABee75a155B8aA4b8c9983e2deF',


}

export { MULTICALL_ABI, MULTICALL_NETWORKS }
