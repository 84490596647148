import {Price} from './fractions/price'
import {TokenAmount} from './fractions/tokenAmount'
import invariant from 'tiny-invariant'
import JSBI from 'jsbi'
import {keccak256, pack} from '@ethersproject/solidity'
import {getCreate2Address} from '@ethersproject/address'


import {
    _1000,
    _997,
    BigintIsh,
    ChainId,
    FACTORY_ADDRESS,
    FIVE,
    INIT_CODE_HASH,
    MINIMUM_LIQUIDITY,
    ONE,
    ZERO
} from '../constants'
import {parseBigintIsh, sqrt} from '../utils'
import {InsufficientInputAmountError, InsufficientReservesError} from '../errors'
import {Token} from './token'

let PAIR_ADDRESS_CACHE: { [token0Address: string]: { [token1Address: string]: string } } = {}

export class Pair {
  public readonly liquidityToken: Token
  private readonly tokenAmounts: [TokenAmount, TokenAmount]

  public static getAddress(tokenA: Token, tokenB: Token): string {
    const tokens = tokenA.sortsBefore(tokenB) ? [tokenA, tokenB] : [tokenB, tokenA] // does safety checks

//    console.log(FACTORY_ADDRESS,INIT_CODE_HASH);
/*    var newPair = getCreate2Address(
            FACTORY_ADDRESS,
            keccak256(['bytes'], [pack(['address', 'address'], [tokens[0].address, tokens[1].address])]),
            INIT_CODE_HASH
          )
    console.log(tokenA, tokenB, newPair);
*/


    const WETH = '0x4300000000000000000000000000000000000004';
    const USDB = '0x4300000000000000000000000000000000000003';
    const YES = '0x20fE91f17ec9080E3caC2d688b4EcB48C5aC3a9C';
    const MIA = '0xA4C7aA67189EC5623121c6C94Ec757DfeD932D4B';
    const OLE = '0x73c369F61c90f03eb0Dd172e95c90208A28dC5bc';
    const PUMP = '0x216A5a1135A9dab49FA9Ad865E0f22FE22b5630A';
    const BAJA = '0x5FE8534a6F96cb01261Bd96e98c17C2c1Cab3204';
    const BEPE = '0xB582Dc28968c725D2868130752aFa0c13EbF9b1a';
    const ALIEN = '0xCa84812E477eE5a96a92328689D8Ce2589aB6FfD';
    const ORBIT = '0x42E12D42b3d6C4A74a88A61063856756Ea2DB357';
    const PACM = '0x0B4d0ee29857c3961b380d4ec138EA5814E346b9';
    const GLORY = '0xd582879453337BD149Ae53EC2092B0af5281d1D7';
    const AI = '0x764933fbAd8f5D04Ccd088602096655c2ED9879F';
    const BAG = '0xb9dfCd4CF589bB8090569cb52FaC1b88Dbe4981F';
    const VROOM = '0x891c9B37177Bdf8Edc891119C9d8aEefDa9A5246';
    const ETHx = '0x5A7651Dd5C9d72fc4FDD4f9706193F33DFb4122d';

    
//    const ETH_USDB = '0x8dca9F38cE1916A4e4b170667f2bdf0262b0F4a4';
    const ETH_USDB = '0xc9C55e30DEBc2be5afeC0cf0F1676fD90a576215';
    var YES_WETH = '0x2C4205351BdF9de525ae5697684304ceC3400Bae';
    var WETH_MIA = '0xbA048EDE17488b7cA494eAF78e57Ba3Fb57c2F19';
    var WETH_OLE = '0xe1d7e42bb2d113eBF5b249E6B906E36852A0e6a1';
    var PUMP_WETH = '0x101D3f2b45C603706B5f7Fcfc599E5f889B7D986';
    var WETH_BAJA = '0xa7373458CC1A152E9292DB69c1AAEe7c9E645696';
    var WETH_BEPE = '0xab4f6Aa244590F869010a1B9516bF5C8FC683bc3';
    var WETH_ALIEN = '0x31cC949B9e8EB3D76bbFC34F3513eFbB5351d6A7';
    var ORBIT_WETH = '0xc0d4B1d788d4ffd00a72b2A3a83361C4a2540BCb';
    var PACM_WETH = '0x60636673986e90288165cC08B4BA350eB7d092fF';
    var WETH_GLORY = '0xba38D1E16dd6fb9552051478De6a8D8035fAbCC6';
    var WETH_AI = '0x566706787Cb51c3EC35d39C96Ed2D8eD6ad8a313';
    var WETH_BAG = '0xB43D2170137ad19C91779932f14712bF4AB299fd';
    var WETH_VROOM = '0xC4Cb75FDa6d173ce149bD521215F8aaD79C62aed';
    var WETH_ETHx = '0xc693cBE73f0Afbb0c62D0eEDdc75754aD248575C';


    //ETH_USDB
    if((tokens[0].address == WETH && tokens[1].address == USDB) || (tokens[1].address == WETH && tokens[0].address == USDB)){
      return ETH_USDB;
    }
    //YES_WETH
    if((tokens[0].address == YES && tokens[1].address == WETH) || (tokens[1].address == YES && tokens[0].address == WETH)){
        return YES_WETH;
    }
    //WETH_MIA
    if((tokens[0].address == WETH && tokens[1].address == MIA) || (tokens[1].address == WETH && tokens[0].address == MIA)){
        return WETH_MIA;
    }
    //WETH_OLE
    if((tokens[0].address == WETH && tokens[1].address == OLE) || (tokens[1].address == WETH && tokens[0].address == OLE)){
        return WETH_OLE;
    }
    //PUMP_WETH
    if((tokens[0].address == PUMP && tokens[1].address == WETH) || (tokens[1].address == PUMP && tokens[0].address == WETH)){
        return PUMP_WETH;
    }
    //WETH_BAJA
    if((tokens[0].address == WETH && tokens[1].address == BAJA) || (tokens[1].address == WETH && tokens[0].address == BAJA)){
        return WETH_BAJA;
    }
    //WETH_BEPE
    if((tokens[0].address == WETH && tokens[1].address == BEPE) || (tokens[1].address == WETH && tokens[0].address == BEPE)){
        return WETH_BEPE;
    }
    //WETH_ALIEN
    if((tokens[0].address == WETH && tokens[1].address == ALIEN) || (tokens[1].address == WETH && tokens[0].address == ALIEN)){
        return WETH_ALIEN;
    }
    //ORBIT_WETH
    if((tokens[0].address == ORBIT && tokens[1].address == WETH) || (tokens[1].address == ORBIT && tokens[0].address == WETH)){
        return ORBIT_WETH;
    }
    //PACM_WETH
    if((tokens[0].address == PACM && tokens[1].address == WETH) || (tokens[1].address == PACM && tokens[0].address == WETH)){
        return PACM_WETH;
    }
    //WETH_GLORY
    if((tokens[0].address == WETH && tokens[1].address == GLORY) || (tokens[1].address == WETH && tokens[0].address == GLORY)){
        return WETH_GLORY;
    }
    //WETH_AI
    if((tokens[0].address == WETH && tokens[1].address == AI) || (tokens[1].address == WETH && tokens[0].address == AI)){
        return WETH_AI;
    }
    //WETH_BAG
    if((tokens[0].address == WETH && tokens[1].address == BAG) || (tokens[1].address == WETH && tokens[0].address == BAG)){
        return WETH_BAG;
    }
    //WETH_VROOM
    if((tokens[0].address == WETH && tokens[1].address == VROOM) || (tokens[1].address == WETH && tokens[0].address == VROOM)){
        return WETH_VROOM;
    }
    //WETH_ETHx
    if((tokens[0].address == WETH && tokens[1].address == ETHx) || (tokens[1].address == WETH && tokens[0].address == ETHx)){
        return WETH_ETHx;
    }

    if (PAIR_ADDRESS_CACHE?.[tokens[0].address]?.[tokens[1].address] === undefined) {
      PAIR_ADDRESS_CACHE = {
        ...PAIR_ADDRESS_CACHE,
        [tokens[0].address]: {
          ...PAIR_ADDRESS_CACHE?.[tokens[0].address],
          [tokens[1].address]: getCreate2Address(
            FACTORY_ADDRESS,
            keccak256(['bytes'], [pack(['address', 'address'], [tokens[0].address, tokens[1].address])]),
            INIT_CODE_HASH
          )
        }
      }
    }
//    console.log(PAIR_ADDRESS_CACHE[tokens[0].address][tokens[1].address]);

    return PAIR_ADDRESS_CACHE[tokens[0].address][tokens[1].address]
  }

  public constructor(tokenAmountA: TokenAmount, tokenAmountB: TokenAmount) {
    const tokenAmounts = tokenAmountA.token.sortsBefore(tokenAmountB.token) // does safety checks
      ? [tokenAmountA, tokenAmountB]
      : [tokenAmountB, tokenAmountA]
    this.liquidityToken = new Token(
      tokenAmounts[0].token.chainId,
      Pair.getAddress(tokenAmounts[0].token, tokenAmounts[1].token),
      18,
      'SLP-V2',
      'SpaceSwap V2'
    )
    this.tokenAmounts = tokenAmounts as [TokenAmount, TokenAmount]
  }

  /**
   * Returns true if the token is either token0 or token1
   * @param token to check
   */
  public involvesToken(token: Token): boolean {
    return token.equals(this.token0) || token.equals(this.token1)
  }

  /**
   * Returns the current mid price of the pair in terms of token0, i.e. the ratio of reserve1 to reserve0
   */
  public get token0Price(): Price {
    return new Price(this.token0, this.token1, this.tokenAmounts[0].raw, this.tokenAmounts[1].raw)
  }

  /**
   * Returns the current mid price of the pair in terms of token1, i.e. the ratio of reserve0 to reserve1
   */
  public get token1Price(): Price {
    return new Price(this.token1, this.token0, this.tokenAmounts[1].raw, this.tokenAmounts[0].raw)
  }

  /**
   * Return the price of the given token in terms of the other token in the pair.
   * @param token token to return price of
   */
  public priceOf(token: Token): Price {
    invariant(this.involvesToken(token), 'TOKEN')
    return token.equals(this.token0) ? this.token0Price : this.token1Price
  }

  /**
   * Returns the chain ID of the tokens in the pair.
   */
  public get chainId(): ChainId {
    return this.token0.chainId
  }

  public get token0(): Token {
    return this.tokenAmounts[0].token
  }

  public get token1(): Token {
    return this.tokenAmounts[1].token
  }

  public get reserve0(): TokenAmount {
    return this.tokenAmounts[0]
  }

  public get reserve1(): TokenAmount {
    return this.tokenAmounts[1]
  }

  public reserveOf(token: Token): TokenAmount {
    invariant(this.involvesToken(token), 'TOKEN')
    return token.equals(this.token0) ? this.reserve0 : this.reserve1
  }

  public getOutputAmount(inputAmount: TokenAmount): [TokenAmount, Pair] {
    invariant(this.involvesToken(inputAmount.token), 'TOKEN')
    if (JSBI.equal(this.reserve0.raw, ZERO) || JSBI.equal(this.reserve1.raw, ZERO)) {
      throw new InsufficientReservesError()
    }
    const inputReserve = this.reserveOf(inputAmount.token)
    const outputReserve = this.reserveOf(inputAmount.token.equals(this.token0) ? this.token1 : this.token0)
    const inputAmountWithFee = JSBI.multiply(inputAmount.raw, _997)
    const numerator = JSBI.multiply(inputAmountWithFee, outputReserve.raw)
    const denominator = JSBI.add(JSBI.multiply(inputReserve.raw, _1000), inputAmountWithFee)
    const outputAmount = new TokenAmount(
      inputAmount.token.equals(this.token0) ? this.token1 : this.token0,
      JSBI.divide(numerator, denominator)
    )
    if (JSBI.equal(outputAmount.raw, ZERO)) {
      throw new InsufficientInputAmountError()
    }
    return [outputAmount, new Pair(inputReserve.add(inputAmount), outputReserve.subtract(outputAmount))]
  }

  public getInputAmount(outputAmount: TokenAmount): [TokenAmount, Pair] {
    invariant(this.involvesToken(outputAmount.token), 'TOKEN')
    if (
      JSBI.equal(this.reserve0.raw, ZERO) ||
      JSBI.equal(this.reserve1.raw, ZERO) ||
      JSBI.greaterThanOrEqual(outputAmount.raw, this.reserveOf(outputAmount.token).raw)
    ) {
      throw new InsufficientReservesError()
    }

    const outputReserve = this.reserveOf(outputAmount.token)
    const inputReserve = this.reserveOf(outputAmount.token.equals(this.token0) ? this.token1 : this.token0)
    const numerator = JSBI.multiply(JSBI.multiply(inputReserve.raw, outputAmount.raw), _1000)
    const denominator = JSBI.multiply(JSBI.subtract(outputReserve.raw, outputAmount.raw), _997)
    const inputAmount = new TokenAmount(
      outputAmount.token.equals(this.token0) ? this.token1 : this.token0,
      JSBI.add(JSBI.divide(numerator, denominator), ONE)
    )
    return [inputAmount, new Pair(inputReserve.add(inputAmount), outputReserve.subtract(outputAmount))]
  }

  public getLiquidityMinted(
    totalSupply: TokenAmount,
    tokenAmountA: TokenAmount,
    tokenAmountB: TokenAmount
  ): TokenAmount {
    invariant(totalSupply.token.equals(this.liquidityToken), 'LIQUIDITY')
    const tokenAmounts = tokenAmountA.token.sortsBefore(tokenAmountB.token) // does safety checks
      ? [tokenAmountA, tokenAmountB]
      : [tokenAmountB, tokenAmountA]
    invariant(tokenAmounts[0].token.equals(this.token0) && tokenAmounts[1].token.equals(this.token1), 'TOKEN')

    let liquidity: JSBI
    if (JSBI.equal(totalSupply.raw, ZERO)) {
      liquidity = JSBI.subtract(sqrt(JSBI.multiply(tokenAmounts[0].raw, tokenAmounts[1].raw)), MINIMUM_LIQUIDITY)
    } else {
      const amount0 = JSBI.divide(JSBI.multiply(tokenAmounts[0].raw, totalSupply.raw), this.reserve0.raw)
      const amount1 = JSBI.divide(JSBI.multiply(tokenAmounts[1].raw, totalSupply.raw), this.reserve1.raw)
      liquidity = JSBI.lessThanOrEqual(amount0, amount1) ? amount0 : amount1
    }
    if (!JSBI.greaterThan(liquidity, ZERO)) {
      throw new InsufficientInputAmountError()
    }
    return new TokenAmount(this.liquidityToken, liquidity)
  }

  public getLiquidityValue(
    token: Token,
    totalSupply: TokenAmount,
    liquidity: TokenAmount,
    feeOn: boolean = false,
    kLast?: BigintIsh
  ): TokenAmount {
    invariant(this.involvesToken(token), 'TOKEN')
    invariant(totalSupply.token.equals(this.liquidityToken), 'TOTAL_SUPPLY')
    invariant(liquidity.token.equals(this.liquidityToken), 'LIQUIDITY')
    invariant(JSBI.lessThanOrEqual(liquidity.raw, totalSupply.raw), 'LIQUIDITY')

    let totalSupplyAdjusted: TokenAmount
    if (!feeOn) {
      totalSupplyAdjusted = totalSupply
    } else {
      invariant(!!kLast, 'K_LAST')
      const kLastParsed = parseBigintIsh(kLast)
      if (!JSBI.equal(kLastParsed, ZERO)) {
        const rootK = sqrt(JSBI.multiply(this.reserve0.raw, this.reserve1.raw))
        const rootKLast = sqrt(kLastParsed)
        if (JSBI.greaterThan(rootK, rootKLast)) {
          const numerator = JSBI.multiply(totalSupply.raw, JSBI.subtract(rootK, rootKLast))
          const denominator = JSBI.add(JSBI.multiply(rootK, FIVE), rootKLast)
          const feeLiquidity = JSBI.divide(numerator, denominator)
          totalSupplyAdjusted = totalSupply.add(new TokenAmount(this.liquidityToken, feeLiquidity))
        } else {
          totalSupplyAdjusted = totalSupply
        }
      } else {
        totalSupplyAdjusted = totalSupply
      }
    }

    return new TokenAmount(
      token,
      JSBI.divide(JSBI.multiply(liquidity.raw, this.reserveOf(token).raw), totalSupplyAdjusted.raw)
    )
  }
}
